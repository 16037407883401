import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { API } from '../backend';
import Cookies from 'js-cookie';
import "./VerifyPayment.css";

const VerifyPayment = () => {
  const [searchParams] = useSearchParams();
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState("");

  useEffect(() => {
    const verifyPayment = async () => {
      const authority = searchParams.get('Authority');
      const status = searchParams.get('Status');
      const testType = searchParams.get('testType');

      if (status === 'OK') {
        try {
        const response = await fetch(`${API}api/user/verify_payment/`, {

            method: 'POST',
            headers: {
              'Authorization': 'Token ' + Cookies.get('token'),
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              test_type: testType,
              authority: authority,
            }),
          });

          const result = await response.json();

          if (result.success) {
            setIsSuccessful(true);
            setMessage("پرداخت شما موفقیت‌آمیز بود! ممنون از خرید شما.");
          } else {
            setIsSuccessful(false);
            setMessage("پرداخت شما ناموفق بود. لطفاً مجدداً تلاش کنید.");
          }
        } catch (error) {
          setIsSuccessful(false);
          setMessage("مشکلی در فرآیند پرداخت پیش آمد. لطفاً دوباره تلاش کنید.");
        }
      } else {
        setIsSuccessful(false);
        setMessage("پرداخت شما لغو شده است.");
      }

      setIsLoading(false);
    };

    verifyPayment();
  }, [searchParams]);

  if (isLoading) {
    return (
      <div className="success-container">
        <div className="success-card">
          <h1 className="success-title">در حال بررسی پرداخت...</h1>
          <p className="success-message">لطفاً چند لحظه صبر کنید.</p>
        </div>
      </div>
    );
  }
  

  return (
    <div className={`success-container ${isSuccessful ? "success" : "failure"}`}>
      <div className="success-card">
        <h1 className="success-title">{isSuccessful ? "پرداخت موفق!" : "پرداخت ناموفق!"}</h1>
        <p className="success-message">{message}</p>
        <button
          className="success-button"
          onClick={() => (window.location.href = "/profile")}
        >
          {isSuccessful ? "ادامه خرید" : "تلاش مجدد"}
        </button>
      </div>
    </div>
  );
};

export default VerifyPayment;

