import React, { useState } from "react";
import styled from "styled-components";
import jalaali from 'jalaali-js'; // Import jalaali-js library
import TourModal from "./TourModal"; // Import the modal component
import { useNavigate } from "react-router-dom";

export function TourCard({ tourData }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null); // State to track the clicked image

  const coverImage = tourData.images && tourData.images.length > 0 ? tourData.images[0].image : ''; // Set the first image as cover
  const remainingImages = tourData.images && tourData.images.length > 1 ? tourData.images.slice(1) : [];

  // Convert dates to Jalali format using jalaali-js
  const convertToJalali = (gregorianDate) => {
    const date = new Date(gregorianDate);
    const jalaaliDate = jalaali.toJalaali(date.getFullYear(), date.getMonth() + 1, date.getDate());
    return `${jalaaliDate.jy}/${jalaaliDate.jm}/${jalaaliDate.jd}`;
  };

  const startDateJalali = convertToJalali(tourData.start_date);
  const endDateJalali = convertToJalali(tourData.end_date);
  const formattedPrice = parseInt(tourData.price, 10).toLocaleString('fa-IR');

  const handleButtonClick = () => {
    setIsModalOpen(true);
  };

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const closeImageModal = () => {
    setSelectedImage(null);
  };

  const navigate = useNavigate();

  const handleReservationClick = () => {
    navigate(`/tours/${tourData.id}`); // Navigate to the tour details page
  };

  return (
    <>
      <CardWrapper>
        {/* Cover image */}
        {coverImage && (
          <img
            src={coverImage}
            alt="cover"
            className="w-full h-48 object-cover rounded-t-lg"
          />
        )}

        {/* Tour details */}
        <div className="p-4 flex flex-col flex-grow">
          {/* Title */}
          <h3 className="text-center mb-4 font-bold text-sm text-gray-800 overflow-hidden whitespace-nowrap text-ellipsis">{tourData.title}</h3>

          {/* Source and Destination */}
          <p className="text-center mt-4 mb-4 text-sm text-gray-600">
            <strong>مبدا:</strong> {tourData.source} <span className="mx-2">|</span> <strong>مقصد:</strong> {tourData.destination}
          </p>

          {/* Dates */}
          <p className="text-center mt-2 mb-8 text-sm text-gray-600">
            <strong>تاریخ شروع:</strong> {startDateJalali} <span className="mx-2">|</span> <strong>تاریخ پایان:</strong> {endDateJalali}
          </p>

          {/* Price */}
          <div className="text-center text-lg font-bold text-blue-600 mb-4">شروع از {`${formattedPrice} تومان`}</div>

          {/* Tour Description */}
          <p className="text-sm text-gray-600 text-justify">
            {tourData.description.length > 200 ? tourData.description.substring(0, 200) + '...' : tourData.description}
          </p>

          {/* Remaining images gallery */}
          {remainingImages.length > 0 && (
            <div className="tour-images mt-4">
              {remainingImages.map((image, index) => (
                <img
                  key={index}
                  src={image.image}
                  alt={`Tour image ${index + 1}`}
                  className="tour-image"
                  onClick={() => handleImageClick(image.image)}
                />
              ))}
            </div>
          )}

          {/* Reservation Button */}
          <button onClick={handleReservationClick} className="reserve-button">
            برای رزرو کلیک کنید
          </button>
        </div>
      </CardWrapper>

      {/* Modal for displaying detailed tour information */}
      {isModalOpen && <TourModal tourData={tourData} onClose={() => setIsModalOpen(false)} />}

      {/* Image Modal */}
      {selectedImage && (
        <ImageModalWrapper onClick={closeImageModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <img src={selectedImage} alt="Selected" className="modal-image" />
          </div>
        </ImageModalWrapper>
      )}
    </>
  );
}

const CardWrapper = styled.div`
  border: 1px solid #ccc;
  border-radius: 8px;
  position: relative;
  transition: transform 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  .tour-images {
    display: flex;
    gap: 10px;
    margin-top: 10px;
  }

  .tour-image {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 4px;
    border: 1px solid #ccc;
    cursor: pointer;

    &:hover {
      transform: scale(1.2);
      transition: transform 0.2s;
    }
  }

  .reserve-button {
    background-color: #7620ff;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
    margin-top: 20px;

    &:hover {
      background-color: #6108ee;
    }
  }
`;

const ImageModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;

  .modal-content {
    max-width: 90%;
    max-height: 90%;
    position: relative;
  }

  .modal-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
`;
