import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { API } from "../backend";
import { getUserInfo } from '../apis/api';
import './SelectQuantityPage.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlask, faLungs, faSpinner, faMoneyBillWave, faInfoCircle, faArrowRight, faArrowLeft, faPills } from '@fortawesome/free-solid-svg-icons';

const GroupPaymentPage = () => {
  const BLOOD_TEST_PRICE = 35000;       // Price for blood test
  const ANOMALY_TEST_PRICE = 45000;    // Price for anomaly test
  const MEDICATION_PRICE = 35000;

  const [step, setStep] = useState(1); // Step state: 1 = description, 2 = quantity selection
  const [bloodTestQuantity, setBloodTestQuantity] = useState(0);
  const [anomalyTestQuantity, setAnomalyTestQuantity] = useState(0);
  const [medicationQuantity, setMedicationQuantity] = useState(1);
  
  const [isLoadingPayment, setIsLoadingPayment] = useState(false);
  const [userInfo, setUserInfo] = useState([]);
  const [userInfoLoaded, setUserInfoLoaded] = useState(false);
  const [error, setError] = useState(false);

  const navigate = useNavigate();

  const [totalPrice, setTotalPrice] = useState(0);

const calculatePrice = () => {
    // Discount calculation based on quantity
    const getDiscountedPrice = (quantity, basePrice) => {
      let discount = 0;
    
      // Apply discount based on quantity
      if (quantity >= 11) discount = 0.20; // 20% discount
      else if (quantity >= 6) discount = 0.15; // 15% discount
      else if (quantity >= 3) discount = 0.10; // 10% discount
    
      // Calculate and round the price
      return Math.round(quantity * basePrice * (1 - discount)); // Round to nearest integer
    };

    // Calculate final price
    const total = getDiscountedPrice(bloodTestQuantity, BLOOD_TEST_PRICE) +
                  getDiscountedPrice(anomalyTestQuantity, ANOMALY_TEST_PRICE)+
                  getDiscountedPrice(medicationQuantity, MEDICATION_PRICE);

    setTotalPrice(total);
  };

  useEffect(() => {
    calculatePrice();
  }, [bloodTestQuantity, anomalyTestQuantity, medicationQuantity]); 

  // Load user info
  const loadUserInfo = () => {
    getUserInfo().then((data) => {
      if (data.error) {
        setError(data.error);
      } else {
        setUserInfo(data);
        setUserInfoLoaded(true);
      }
    });
  };

  useEffect(() => {
    loadUserInfo();
  }, []);

  // Handle payment
  const handleGroupPayment = async () => {
    try {
      setIsLoadingPayment(true);
      const response = await fetch(`${API}api/user/initiate_group_payment/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'token ' + Cookies.get('token'),
        },
        body: JSON.stringify({
          quantities: {
            blood: bloodTestQuantity,
            anomaly: anomalyTestQuantity,
            medication: medicationQuantity,
          },
          mobile: userInfo.mobile,
          email: userInfo.email,
        }),
      });

      const result = await response.json();

      if (result.authority) {
        window.location.href = result.payment_url; // Redirect to payment gateway
      } else {
        alert('Failed to initiate payment.');
      }
    } catch (error) {
      console.error('Error initiating group payment:', error);
    } finally {
      setIsLoadingPayment(false);
    }
  };
  

  return (
    <div className="payment-page-container">
      <div className="payment-page-card">
        {step === 1 && (
          <>
            {/* Description Step */}
            <div className="payment-page-description">
              <FontAwesomeIcon icon={faInfoCircle} className="payment-page-icon-title" />
              <h3>چرا این مدل خرید طراحی شده است؟</h3>
              <p>
                برای راحتی بیشتر شما، این صفحه به شما امکان می‌دهد ظرفیت آزمایش‌ها را به‌صورت گروهی خریداری کنید.  
                این مدل طراحی شده است تا به جای خرید تکی، به شما اجازه دهد چندین سرویس را هم‌زمان تهیه کنید، 
                و نیازی به تکرار فرآیند پرداخت نداشته باشید.
              </p>
              <p>
                پس از انتخاب تعداد سرویس‌ها، شما به صفحه پرداخت هدایت می‌شوید. پس از اتمام پرداخت، 
                ظرفیت‌های خریداری شده به حساب شما اضافه خواهند شد و بلافاصله آماده استفاده هستند.
              </p>
            </div>
            <button
              onClick={() => setStep(2)}
              className="payment-page-button"
            >
              <FontAwesomeIcon icon={faArrowRight} /> ادامه
            </button>
            <button
              onClick={() => navigate('/profile')}
              className="payment-page-button"
            >
              <FontAwesomeIcon icon={faArrowLeft} /> بازگشت
            </button>
          </>
        )}

        {step === 2 && (
          <>
            {/* Quantity Selection Step */}
            <h2 className="payment-page-title">
              انتخاب تعداد سرویس‌ها
            </h2>
            <div className="payment-page-options">
              <div className="payment-page-option">
                <label className="payment-page-label">
                  <FontAwesomeIcon icon={faFlask} className="payment-page-icon" /> تعداد آزمایش خون:
                </label>
                <input
                  type="number"
                  min="0"
                  value={bloodTestQuantity}
                  onChange={(e) => setBloodTestQuantity(Number(e.target.value))}
                  className="payment-page-input"
                />
                <p className="price-info">
                  {BLOOD_TEST_PRICE.toLocaleString()} تومان / هر عدد
                </p>
              </div>
              <div className="payment-page-option">
                <label className="payment-page-label">
                  <FontAwesomeIcon icon={faLungs} className="payment-page-icon" /> تعداد آزمایش ناهنجاری قفسه سینه:
                </label>
                <input
                  type="number"
                  min="0"
                  value={anomalyTestQuantity}
                  onChange={(e) => setAnomalyTestQuantity(Number(e.target.value))}
                  className="payment-page-input"
                />
                <p className="price-info">
                  {ANOMALY_TEST_PRICE.toLocaleString()} تومان / هر عدد
                </p>
              </div>
              <div className="payment-page-option">
                <label className="payment-page-label">
                  <FontAwesomeIcon icon={faPills} className="payment-page-icon" /> تعداد ظرفیت دارو:
                </label>
                <input
                  type="number"
                  min="0"
                  value={medicationQuantity}
                  onChange={(e) => setMedicationQuantity(Number(e.target.value))}
                  className="payment-page-input"
                />
                <p className="price-info">
                  {MEDICATION_PRICE.toLocaleString()} تومان / هر عدد
                </p>
              </div>
            </div>

            <h3>مبلغ کل: {totalPrice.toLocaleString()} تومان</h3>

            <div className="payment-page-buttons">
              <button
                onClick={() => setStep(1)}
                className="payment-page-button payment-page-back-button"
              >
                <FontAwesomeIcon icon={faArrowLeft} /> بازگشت
              </button>
              <button
                onClick={handleGroupPayment}
                disabled={isLoadingPayment}
                className={`payment-page-button ${isLoadingPayment ? 'payment-page-loading' : ''}`}
              >
                {isLoadingPayment ? (
                  <>
                    <FontAwesomeIcon icon={faSpinner} spin /> در حال پردازش...
                  </>
                ) : (
                  'پرداخت گروهی'
                )}
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default GroupPaymentPage;
