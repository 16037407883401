import React, { useRef } from "react";
// Sections
import TopNavbar from "../components/Nav/TopNavbar";
import Header from "../components/Sections/Header";
import Services from "../components/Sections/Services";
import Projects from "../components/Sections/Projects";
import Blog from "../components/Sections/Blog";
import Pricing from "../components/Sections/Pricing";
import Contact from "../components/Sections/Contact";
import Footer from "../components/Sections/Footer"
import Slogan from "../components/Sections/Slogan";

export default function Landing() {
  const servicesRef = useRef(null);
  return (
    <>
      <TopNavbar />
      <Slogan servicesRef={servicesRef}/>
      <Header />
      <div ref={servicesRef}>
        <Services />
      </div>
      <Projects />
      <Blog />
      {/* <Pricing /> */}
      {/* <Contact /> */}
      <Footer />
    </>
  );
}


