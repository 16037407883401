import React from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
import { SocialIcon } from "react-social-icons"; // Import react-social-icons
import LogoImg from "../../assets/svg/Logo";
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationDot, faPhone } from '@fortawesome/free-solid-svg-icons'

export default function Contact() {

  const getCurrentYear = () => {
    return new Date().getFullYear();
  }

  const { t } = useTranslation();

  return (
    <FooterWrapper>
      <div className="darkBg">
        <div className="container">
          <TopSection className="flexSpaceCenter" style={{ padding: "30px 0" }}>
            <Link className="flexCenter animate pointer" to="teaser" smooth={true} offset={-80}>
              <LogoImg />
              <p className="font15 extraBold whiteColor" style={{ marginLeft: "15px" }}>
                {t('CompanyName')}
              </p>
            </Link>

            <StyleP className="whiteColor font-footer">
              © {getCurrentYear()} - <span className="purpleColor font-footer">HOOSHMAND SALAMAT ANDISHAN SHARIF</span> All Right Reserved
            </StyleP>

            <Link
              className="whiteColor animate pointer font-footer"
              to="#"
              onClick={(e) => {
                e.preventDefault(); // Prevent default link behavior
                window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to the top
              }}
            >
              {t('FooterUp')}
            </Link>
          </TopSection>

          <HorizontalLine />

          <BottomSection>
            <AddressAndSocial className="flexSpaceBetween">
              <AddressWrapper className="whiteColor font13">
                <p>
                <FontAwesomeIcon style={{ marginLeft: '8px' }} className="fontawesome-icon fa-m" icon={ faLocationDot } color="white"/> 
                  تهران، تیموری، خیابان حبیب زادگان، خیابان قاسمی،
                  پلاک 40، مجتمع فناوری امید، طبقه همکف،
                  اتاق 104، استارتاپ سلامتمند
                </p>
              </AddressWrapper>

              <PhoneWrapper className="whiteColor font13">
                <a style={{direction: 'ltr'}} href="tel:+989226001010">
                <FontAwesomeIcon style={{ marginRight: '8px' }} className="fontawesome-icon fa-m" icon={ faPhone } color="white"/>
                  +98 922 600 1010
                </a>
              </PhoneWrapper>

              <SocialIconsWrapper className="flexSpaceCenter">
                <SocialIcon url="https://www.linkedin.com/company/salamatmand/" target="_blank" fgColor="#fff" title="لینکدین" />
                <SocialIcon url="https://t.me/salamatmand" target="_blank" fgColor="#fff" title="تلگرام"/>
                <SocialIcon url="https://www.instagram.com/salamatmand/" target="_blank" fgColor="#fff" title="اینستاگرام"/>
                <SocialIcon url="https://www.youtube.com/@salamatmand_co" target="_blank" fgColor="#fff" title="یوتوب"/>
                <SocialIcon url="mailto:salamatmandco@gmail.com" target="_blank" fgColor="#fff" title="ایمیل"/>
                <SocialIcon network="whatsapp" url="tel:+989307829934" target="_blank" fgColor="#fff" title="تلفن"/>
            </SocialIconsWrapper>
            </AddressAndSocial>
          </BottomSection>
        </div>
      </div>
    </FooterWrapper>
  );
}

// Styled components
const FooterWrapper = styled.div`
  width: 100%;
  background-color: #1b1b1b; /* Ensure consistent dark background */
`;

const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: 20px;

  @media (max-width: 550px) {
    flex-direction: column;
    text-align: center;
  }
`;

const BottomSection = styled.div`
  padding: 20px 0;
`;

const AddressAndSocial = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px 0;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`;

const StyleP = styled.p`
  @media (max-width: 550px) {
    margin: 20px 0;
  }
`;

const AddressWrapper = styled.div`
  line-height: 1.8;
  text-align: justify;
  direction: rtl;
  max-width: 300px;

  @media (max-width: 768px) {
    text-align: justify;
    margin-bottom: 20px;
  }

  a{
    line-height: 1.8;
    text-align: justify;
    direction: ltr !important;
    color: white;
    max-width: 300px;
  }
`;

const PhoneWrapper = styled.div`
  line-height: 1.8;
  text-align: justify;
  // direction: rtl;
  max-width: 300px;

  @media (max-width: 768px) {
    text-align: justify;
    margin-bottom: 20px;
  }

  a{
    line-height: 1.8;
    text-align: justify;
    direction: ltr !important;
    color: white;
    max-width: 300px;
  }
`

const SocialIconsWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;

  a {
    width: 40px !important;
    height: 40px !important;
    transition: transform 0.3s ease, opacity 0.3s ease;
    &:hover {
      transform: scale(1.2); /* Scale the icon slightly on hover */
      opacity: 0.8; /* Optional: Reduce opacity on hover */
    }

    @media (max-width: 550px) {
      width: 30px !important;
      height: 30px !important;
    }
  }
`;

const HorizontalLine = styled.hr`
  border: none;
  height: 1px;
  background: #555;
  margin: 20px 0;
  width: 100%;
`;