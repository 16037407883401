import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretLeft, faCaretRight, faCirclePlay, faCircleStop, faFlask, faBrain,
         faPills, faXRay, faWalking, faUsers, faHeartbeat } from '@fortawesome/free-solid-svg-icons'
import './Slogan.css';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import Signin from "../../screens/Signin";
import Signup from "../../screens/Signup";

export default function Slogan( {servicesRef} ) {
    const videoRef = useRef(null);
    const [isPaused, setIsPaused] = useState(false);
  
    const handleStopClick = () => {
      if (videoRef.current) {
        videoRef.current.pause();
        setIsPaused(true);
      }
    };
  
    const handleResumeClick = () => {
      if (videoRef.current) {
        videoRef.current.play();
        setIsPaused(false);
      }
    };
  const { t } = useTranslation();

  const services = [
    {
      gif: '/Videos/Yadetnare.m4v',
      image: '/Images/thinking.png',
      title: 'یادت نره! ',
      description: 'دیگه لازم نیست مصرف داروهامون یادمون بره! بسپرش به فیچر یادت نره تا برای خودت و والدینت یه یادآور شخصی‌سازی شده بسازی.',
    },
    {
      gif: '/Videos/Blood.m4v',
      image: '/Images/thinking.png',
      title: 'تفسیر هوشمند آزمایش خون',
      description: 'این همه هزینه برای فهمیدن نتایج آزمایش خونت؟! اینجا می‌تونی تو چند ثانیه و کم‌هزینه‌تر بفهمی چی به چیه و برای مراجعه به پزشک متخصص سریع‌تر برنامه‌ریزی کنی!',
    },
    {
      gif: '/Videos/Nahanjari.m4v',
      image: '/Images/thinking.png',
      title: 'تشخیص هوشمند ناهنجاری در تصاویر رادیولوژی',
      description: 'با هوش مصنوعی سلامتمند دیگه حتی میشه عکسای رادیولوژی هم تحلیل کرد. کافیه فقط بهش یه عکس از رادیولوژی قفسه سینه بدی و بهت میگه احتمال ناهنجاری هست یا نه.',
    },
    {
      gif: '/Videos/Cogmo.m4v',
      image: '/Images/thinking.png',
      title: 'کاگمو-جیم',
      description: 'این یه مدل نوآورانه از ورزش‌های فکری و جسمیه که فقط برای شما طراحی شده! دیگه نگران افت عملکرد جسمی-فکری در دوران سلامتمندی نباش.',
    },
    {
      gif: '/Videos/Tour.m4v',
      image: '/Images/thinking.png',
      title: 'رزرو تورهای بهداشتی-تفریحی-آموزشی شخصی‌سازی‌شده',
      description: 'تهران‌گردی و طبیعت‌گردی هیچ وقت برای سلامتمندان انقدر آسون نبوده! اینجا چاشنی غربالگری، آموزش و تفریح هم مخصوص شما تدارک دیده شده. کافیه فقط یه بسته رو انتخاب کنی و بقیه‌شو بسپری دست ما.',
    },
    {
      gif: '/Videos/Elderly.m4v',
      image: '/Images/thinking.png',
      title: 'دسترسی فرزندان به اطلاعات بهداشتی-رفاهی والدین سلامتمند',
      description: 'اینجا می‌تونی به صورت مستمر و با رابط گرافیکی، از روند سلامتمندی عزیزانت مطلع بشی و حتی براشون خدمات آتی رو سفارش بدی!',
    },
    {
      gif: '/Videos/Niknam.m4v',
      image: '/Images/thinking.png',
      title: 'کاهش استرس قبل از اقدامات درمانی',
      description: 'با امکانات جدید سلامتمند، بدون استرس خدمات درمانی بگیر! با آگاهی از فرآیندها، تجربه دیگران و شبیه‌سازی مراحل، آرامش خاطر داشته باش.',
    },
  ];

  const servicesBox = [
    {
      icon: faPills,
      title: 'یادت نره!',
    },
    {
      icon: faFlask,
      title: 'آزمایش خون',
    },
    {
      icon: faXRay,
      title: 'ناهنجاری',
    },
    {
      icon: faBrain,
      title: 'کاگمو-جیم',
    },
    {
      icon: faWalking,
      title: 'تور',
    },
    {
      icon: faHeartbeat,
      title: 'کاهش استرس',
    },
  ];

  const PrevArrow = ({ onClick }) => (
    <div className="custom-arrow prev-arrow" onClick={onClick}>
      <FontAwesomeIcon className="fontawesome-icon-caret fa-m" icon={ faCaretLeft }/>   
    </div>
  );
  
  const NextArrow = ({ onClick }) => (
    <div className="custom-arrow next-arrow" onClick={onClick}>
      <FontAwesomeIcon className="fontawesome-icon-caret fa-m" icon={ faCaretRight }/>   
    </div>
  );

  const navigate = useNavigate();

   const [showSignin, setShowSignin] = useState(false);
    const [showSignup, setShowSignup] = useState(false);
  
    const handleShowSignin = () => {
      setShowSignin(true);
    };
  
    const handleHideSignin = () => {
        setShowSignin(false);
    };
  
    const handleShowSignup = () => {
      setShowSignin(false);
      setShowSignup(true);
  };
  
    const handleHideSignup = () => {
      setShowSignup(false);
    };

  const handleSlideClick = (index) => {
    const token = Cookies.get("token");
    const navigateTo = (path) => {
      if (token) {
        navigate(path);
      } else {
        handleShowSignin();
      }
    };
    switch (index) {
      case 0: // Example for first slide
        navigateTo('/profile');
        break;
      case 1:
        navigateTo('/profile');
        break;
      case 2:
        navigateTo('/profile');
        break;
      case 3:
        navigateTo('/profile');
        break;
      case 4:
        servicesRef.current?.scrollIntoView({ behavior: 'smooth' });
        break;
      case 5:
        navigateTo('/profile');
        break;
      case 6:
        navigateTo('/profile');
          break;
      default:
        navigate('/profile');
    }
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    appendDots: (dots) => (
      <div>
        <div className="custom-dots-container">
          {/* Connect the arrows to slider methods */}
          <PrevArrow onClick={() => sliderRef.current.slickPrev()} />
          <ul className="custom-dots"> {dots} </ul>
          <NextArrow onClick={() => sliderRef.current.slickNext()} />
        </div>
      </div>
    ),
    customPaging: () => (
      <div className="custom-dot"></div> // Custom dot style
    ),
  };
  
  const sliderRef = useRef(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 800);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 800);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize); // Listen for window resize
    return () => window.removeEventListener("resize", handleResize); // Cleanup
  }, []); // Empty dependency array ensures it runs only once on mount

  const DesktopView = () => (
    <div id="teaser" className="background-component">
      <div className="custom-shape"> {/* Desktop Shape */}
        <Slider ref={sliderRef} {...settings}>
          {services.map((service, index) => (
            <div key={index} className="content-container-landing-slogan">
              <div className="gif-box">
                <img src={service.image} alt={`Service ${index + 1}`} />
                {/* <img src={service.gif} alt={`Service ${index + 1}`} /> */}
              </div>
              <div className="text-box">
                <div className="description-box">
                  <h2 className="service-title-h">{service.title}</h2>
                  <p className="service-title-p">{service.description}</p>
                </div>
                <div className="image-box">
                  {/* <img src={service.image} alt={`Service ${index + 1}`} /> */}
                  {/* <img src={service.gif} alt={`Service ${index + 1}`} /> */}
                  <video
                    src={service.gif}
                    autoPlay
                    loop
                    muted
                    playsInline
                    className="video-element"
                />
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
      <div className="feature-boxes-container">
        {servicesBox.map((service, index) => (
          <div key={index} onClick={() => handleSlideClick(index)} className="feature-box">
            <FontAwesomeIcon icon={service.icon} />
            <h3>{service.title}</h3>
          </div>
        ))}
      </div>
    </div>
  );

  // Mobile View
  const MobileView = () => (
    <>
    <div id="teaser" className="mobile-background">
      <Slider ref={sliderRef} {...settings}>
        {services.map((service, index) => (
          <div key={index} className="mobile-content-container">
                <div className="gif-box-mobile">
                  {/* <img src={service.image} alt={`Service ${index + 1}`} /> */}
                  {/* <img src={service.gif} alt={`Service ${index + 1}`} /> */}
                  <video
                    src={service.gif}
                    autoPlay
                    loop
                    muted
                    playsInline
                    className="video-element"
                />
                </div>
            <div className="text-box">
            <div className="description-box">
              <h2 className="service-title-h">{service.title}</h2>
              <p className="service-title-p">{service.description}</p>
            </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
    <div className="feature-boxes-container">
        {servicesBox.map((service, index) => (
          <div key={index} onClick={() => handleSlideClick(index)} className="feature-box">
            <FontAwesomeIcon icon={service.icon} className="fa-xl" />
            <h3>{service.title}</h3>
          </div>
        ))}
      </div>
    </>
  );

  return (
    <div id="teaser" className="background-component">
       { isMobile ? <MobileView /> : <DesktopView />}
       {showSignin && <Signin handleClose={handleHideSignin} handleShowSignup={handleShowSignup}/>}
       {showSignup && <Signup handleClose={handleHideSignup}/>}
    </div>
  );
}
