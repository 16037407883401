import React from 'react';
import { Route, Link, Routes, BrowserRouter as Router} from "react-router-dom"
import { render } from "react-dom";
import Landing from "./screens/Landing"
import Profile from './screens/Profile';
import { MyBlogs } from "./pages/myblogs/MyBlogs";
import { ViewAllBlogs } from "./pages/viewallblogs/ViewAllBlogs";
import { ViewSearchedBlogs } from "./pages/viewseachedblogs/ViewSearchedBlogs";
import { ViewSingleBlog } from "./pages/viewsingleblog/ViewSingleBlog";
import { CreateBlog } from "./pages/createblog/CreateBlog";
import { EditBlog } from "./pages/editblog/EditBlog";
import { QueryClient, QueryClientProvider } from "react-query";
import { ToastContainer, toast } from "react-toastify";
import Blog from './blog/Blog';
import { Login } from "./pages/login/Login";
import { Home } from './pages/home/Home';
import 'react-toastify/dist/ReactToastify.css';
import Packaging from './screens/packaging';
import TourDetails from './screens/TourDetails';
import VerifyPayment from './screens/VerifyPayment';
import TestResult from './screens/TestResult';
import SelectQuantityPage from './screens/SelectQuantityPage';
import VerifyGroupPayment from './screens/VerifyGroupPayment';
const queryClient = new QueryClient();

const RoutesFile = () => {
    return (
        <QueryClientProvider client={queryClient}>
            <ToastContainer position="bottom-right" autoClose={1500} style={{ fontSize: "0.7rem" }} />
            <Routes>
                <Route exact path="/" element={<Landing />} /> 
                <Route path="/tours/:id" element={<TourDetails />} />
                {/* <Route exact path="/ppp" element={<Packaging />} />  */}
                <Route path="/select-quantity" element={<SelectQuantityPage />} />
                <Route path="/blog/login" element={<Login />} />
                <Route exact path="/profile" element={<Profile />} /> 
                <Route path="/test-result" element={<TestResult />} />
                <Route exact path="/verifyPayment" element={<VerifyPayment />} /> 
                <Route path="/verifyGroupPayment" element={<VerifyGroupPayment />} />
                <Route path="/blog" element={<Home />}>
                    <Route path="/blog" element={<ViewAllBlogs />} />
                    <Route path="/blog/view/:blogId/:blogSlug" element={<ViewSingleBlog />} />
                    <Route path="/blog/create" element={<CreateBlog />} />
                    <Route path="/blog/edit/:blogId/" element={<EditBlog />} />
                    <Route path="/blog/search" element={<ViewSearchedBlogs />} />
                    <Route path="/blog/me/blogs" element={<MyBlogs />} />
                </Route>
            </Routes>
        </QueryClientProvider>  
    )
}

export default RoutesFile;