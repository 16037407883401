import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faInfoCircle,
  faHeartbeat,
  faNotesMedical,
  faExclamationTriangle,
  faLightbulb,
  faSearchPlus,
  faCheckCircle,
  faFlask,
} from '@fortawesome/free-solid-svg-icons';
import "./TestResult.css";
import Footer from "../components/Sections/Footer"
import ProfileNavbar from "../components/Nav/ProfileNavbar";
import Cookies from "js-cookie";

const TestResult = () => {
  useEffect(() => {
    if(!Cookies.get("token")){
      navigate("/")
    }
  }, []);
  
  const navigate = useNavigate();
  const result = JSON.parse(localStorage.getItem("testResult"));

  if (!result) {
    return (
      <div className="test-result-no-result">
        <p>نتایج یافت نشد. لطفاً دوباره آزمایش خود را آپلود کنید.</p>
        <button className="test-result-upload-button" onClick={() => navigate("/")}>
          بازگشت به صفحه آپلود
        </button>
      </div>
    );
  }

  const getSectionIcon = (shortcode) => {
    switch (shortcode) {
      case 'introduction':
        return faInfoCircle;
      case 'overall_health_assessment':
        return faHeartbeat;
      case 'detailed_health_analysis':
        return faNotesMedical;
      case 'risk_factors':
        return faExclamationTriangle;
      case 'recommendations':
        return faLightbulb;
      case 'further_evaluation':
        return faSearchPlus;
      case 'conclusion':
        return faCheckCircle;
      case 'parameters':
        return faFlask;
      default:
        return null;
    }
  };


  return (
    <>
    <ProfileNavbar />
    <div className="test-result-page">
      <header className="test-result-header">
        <h1 className="test-result-main-title">نتایج تحلیل آزمایش خون</h1>
      </header>
      <div className="test-result-content">
        {result.interpretation.map((section) => (
          <section key={section.shortcode} className={`test-result-section test-result-section-${section.shortcode}`}>
            <h2 className="test-result-section-title">
              <FontAwesomeIcon icon={getSectionIcon(section.shortcode)} /> {section.title}
            </h2>
            {section.subsections.map((subsection) => (
              <div key={subsection.shortcode} className={`test-result-subsection test-result-subsection-${subsection.shortcode}`}>
                <h3 className="test-result-subsection-title">{subsection.subtitle}</h3>
                <ul className="test-result-item-list">
                    {subsection.items.map((item, index) => (
                        <li key={index} className="test-result-item">
                        <span className="test-result-item-bullet"></span>
                        {item.item}
                        </li>
                    ))}
                    </ul>
              </div>
            ))}
          </section>
        ))}

        <section className="test-result-section-parameters">
          <h2 className="test-result-section-title">
            <FontAwesomeIcon icon={faFlask} /> پارامترها
          </h2>
          <div className="test-result-parameters">
            {result.parameters.map((param, index) => (
              <div key={index} className="test-result-parameter-card">
                <h3 className="test-result-parameter-title">{param.long_name} ({param.short_name})</h3>
                <p className="test-result-parameter-category">{param.category}</p>
                <p className="test-result-parameter-description">{param.short_description}</p>
                <p className="test-result-parameter-result">
                  نتیجه: {param.result} {param.unit} ({param.evaluation || "Normal"})
                </p>
                {param.type === "range" && (
                  <div className="test-result-parameter-range">
                    <p>محدوده طبیعی: {param.range_normal_min} - {param.range_normal_max} {param.unit}</p>
                    <p>محدوده بهینه: {param.range_optimal_min} - {param.range_optimal_max} {param.unit}</p>
                  </div>
                )}
                <p dangerouslySetInnerHTML={{ __html: param.long_description }} className="test-result-parameter-long-description" />
              </div>
            ))}
          </div>
        </section>
      </div>
      <footer className="test-result-footer">
        <button className="test-result-upload-button" onClick={() => navigate("/")}>
          بازگشت به داشبورد
        </button>
      </footer>
      
    </div>
    <Footer />
    </>

  );
};

export default TestResult;
