import React, { useEffect, useState } from "react";
import { Link} from "react-router-dom";
import styled from "styled-components";
// import { Link } from "react-scroll";
// Components
import Sidebar from "../Nav/Sidebar";
import Backdrop from "../Elements/Backdrop";
// Assets
import LogoIcon from "../../assets/svg/Logo";
import BurgerIcon from "../../assets/svg/BurgerIcon";
import Signup from "../../screens/Signup";
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from '../../components/LanguageSwitcher';
import Cookies from "js-cookie";
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import { faHomeAlt, faRightFromBracket, faUserAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Logout from "../../screens/Logout";
import Signin from "../../screens/Signin";
import ProfileSidebar from "./ProfileSidebar";

export default function ProfileNavbar() {
  const { t } = useTranslation();
  const [y, setY] = useState(window.scrollY);
  const [sidebarOpen, toggleSidebar] = useState(false);


  useEffect(() => {
    window.addEventListener("scroll", () => setY(window.scrollY));
    return () => {
      window.removeEventListener("scroll", () => setY(window.scrollY));
    };
  }, [y]);

  const [showLogout, setShowLogout] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [showSignin, setShowSignin] = useState(false);

  const handleShowLogin = () => {
    setShowSignin(false);
    setShowLogin(true);
  };

  const handleHideLogin = () => {
      setShowLogin(false);
  };

  const handleShowSignin = () => {
    setShowSignin(true);
  };

  const handleHideSignin = () => {
      setShowSignin(false);
  };

  const handleShowLogout = () => {
      setShowLogout(true);
  };

  const handleHideLogout = () => {
      setShowLogout(false);
  };
  
  const navigate = useNavigate();

  const navigateProfile = () => {
      navigate('/profile');
  };

  return (
    <>
      {/* <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} /> */}
      <ProfileSidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
      {sidebarOpen && <Backdrop toggleSidebar={toggleSidebar} />}
      <Wrapper className="flexCenter animate whiteBg" style={y > 100 ? { height: "70px", borderBottom: "1px solid black" } : { height: "80px", borderBottom: "1px solid black" }}>
        <NavInner className="container flexSpaceCenter">
            <Link className="pointer flexNullCenter" to="/" smooth={true}>
                <LogoIcon />
                <h1 style={{ marginLeft: "15px" }} className="green-text font20 extraBold">
                <p>{t('CompanyName')}</p>
                </h1>
            </Link>
          <BurderWrapper className="pointer" onClick={() => toggleSidebar(!sidebarOpen)}>
            <BurgerIcon />
          </BurderWrapper>
          {/* <LanguageSwitcher /> */}
          {!Cookies.get('token') && <UlWrapperRight className="flexNullCenter">
            <li onClick={handleShowLogin} className="semiBold font15 pointer">
              <a className="green-text" style={{ padding: "10px 30px 10px 0" }}>
              {t('SignUp')}
              </a>
            </li>
            <li onClick={handleShowSignin} className="semiBold font15 pointer flexCenter">
              <a className="green-text radius8 lightBg" style={{ padding: "10px 15px" }}>
              {t('Start')}
              </a>
            </li>
          </UlWrapperRight>}
          
          {Cookies.get('token') && <UlWrapperRight className="flexNullCenter">
            <li onClick={navigateProfile} className="semiBold font15 pointer flexCenter">
              <a className="green-text radius8 lightBg" style={{ padding: "10px 15px" }}>
              <FontAwesomeIcon icon={ faUserAlt }/>
              </a>
            </li>
          </UlWrapperRight>}
        </NavInner>
      </Wrapper>
      {showLogin && <Signup handleClose={handleHideLogin}/>}
      {showSignin && <Signin handleClose={handleHideSignin} handleShowSignup={handleShowLogin}/>}
      {showLogout && <Logout handleClose={handleHideLogout} />}

    </>
  );
}

const Wrapper = styled.nav`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
`;
const NavInner = styled.div`
  position: relative;
  height: 100%;
`
const BurderWrapper = styled.button`
  outline: none;
  border: 0px;
  background-color: transparent;
  height: 100%;
  padding: 0 15px;
  display: none;
  @media (max-width: 760px) {
    display: block;
  }
`;
const UlWrapper = styled.ul`
  display: flex;
  @media (max-width: 760px) {
    display: none;
  }
`;
const UlWrapperRight = styled.ul`
  @media (max-width: 760px) {
    display: none;
  }
`;


