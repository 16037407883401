import React, {useState} from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
// Assets
import CloseIcon from "../../assets/svg/CloseIcon";
import LogoIcon from "../../assets/svg/Logo";
import Signup from "../../screens/Signup";
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from '../../components/LanguageSwitcher';
import Signin from "../../screens/Signin";
import Cookies from "js-cookie";
import Logout from "../../screens/Logout";
import { Navigate, useNavigate, useLocation } from "react-router-dom";

export default function ProfileSidebar({ sidebarOpen, toggleSidebar }) {
  const [showLogin, setShowLogin] = useState(false);
  const [showSignin, setShowSignin] = useState(false);
  const [showLogout, setShowLogout] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const navigateHomeOrProfile = () => {
    if (location.pathname === "/profile") {
      navigate('/');
    } else {
      navigate('/profile');
    }
  };

  const navigateHome = () => {
    navigate('/');
  };

  const { t } = useTranslation();

  const handleShowLogin = () => {
    setShowSignin(false);
    setShowLogin(true);
    toggleSidebar(!sidebarOpen)
  };

  const handleHideLogin = () => {
      setShowLogin(false);
  };

  const handleShowSignin = () => {
    setShowSignin(true);
    toggleSidebar(!sidebarOpen)
  };

  const handleHideSignin = () => {
      setShowSignin(false);
  };

  const handleShowLogout = () => {
    setShowLogout(true);
    toggleSidebar(!sidebarOpen)
  };

  const handleHideLogout = () => {
    setShowLogout(false);
  };
  return (
    <>
    <Wrapper className="animate darkBg" sidebarOpen={sidebarOpen}>
      <SidebarHeader className="flexSpaceCenter">
        <div className="flexNullCenter">
          <LogoIcon />
          <h1 className="whiteColor font20" style={{ marginLeft: "15px" }}>
          {t('CompanyName')}
          </h1>
        </div>
        <CloseBtn onClick={() => toggleSidebar(!sidebarOpen)} className="animate pointer">
          <CloseIcon />
        </CloseBtn>
      </SidebarHeader>

      {!Cookies.get("token") && <div>
        <UlStyle className="flexSpaceCenter">
          <li onClick={handleShowLogin} className="semiBold font15 pointer">
            <a className="radius8 lightBg" style={{ padding: "10px 15px" }}>
            {t('SignUp')}
            </a>
          </li>
          <li onClick={handleShowSignin} className="semiBold font15 pointer flexCenter">
            <a className="radius8 lightBg" style={{ padding: "10px 15px" }}>
            {t('Start')}
            </a>
          </li>
        </UlStyle>
      </div>}

      {Cookies.get("token") && <div>
        <UlStyle className="flexNullCenter flexColumn">
          <li className="semiBold font15 pointer">
            <Link
              onClick={navigateHomeOrProfile}
              activeClass="active"
              className="whiteColor"
              style={{ padding: "10px 15px" }}
            >
              {location.pathname === "/profile" ? t('NavbarHome') : t('Profile')}
            </Link>
          </li>
        </UlStyle>
        <UlStyle className="">
          <li onClick={handleShowLogout} className="semiBold font15 pointer" style={{textAlign: "center", margin: "auto" }}>
            <a className="radius8 lightBg" style={{ padding: "10px 15px", textAlign: "center", margin: "auto" }}>
            {t('Logout')}
            </a>
          </li>
        </UlStyle>
      </div>}
    </Wrapper>
      {showLogin && <Signup handleClose={handleHideLogin}/>}
      {showSignin && <Signin handleClose={handleHideSignin} handleShowSignup={handleShowLogin}/>}
      {showLogout && <Logout handleClose={handleHideLogout}/>}
    </>

  );
}

const Wrapper = styled.nav`
  width: 400px;
  height: 100vh;
  position: fixed;
  top: 0;
  padding: 0 30px;
  right: ${(props) => (props.sidebarOpen ? "0px" : "-400px")};
  z-index: 9999;
  @media (max-width: 400px) {
    width: 100%;
  }
`;
const SidebarHeader = styled.div`
  padding: 20px 0;
`;
const CloseBtn = styled.button`
  border: 0px;
  outline: none;
  background-color: transparent;
  padding: 10px;
`;
const UlStyle = styled.ul`
  padding: 40px;
  li {
    margin: 20px 0;
  }
`;
