import "leaflet/dist/leaflet.css";
import { MapContainer, TileLayer, Marker, Popup, Polyline } from "react-leaflet";
import { divIcon } from "leaflet";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faLocationDot } from '@fortawesome/free-solid-svg-icons'
import { renderToStaticMarkup } from "react-dom/server";

const createFontAwesomeIcon = (color) =>
    divIcon({
      html: renderToStaticMarkup(
        <FontAwesomeIcon
          icon={faLocationDot}
          style={{ color: color, fontSize: "24px" }}
        />
      ),
      className: "",
      iconSize: [24, 24],
      iconAnchor: [12, 24],
    });

// Props: source, destination, sourceCoords, destinationCoords
const TourMap = ({ source, destination, sourceCoords, destinationCoords }) => {
  const polyline = [sourceCoords, destinationCoords];

  const sourceIcon = createFontAwesomeIcon("green");
  const destinationIcon = createFontAwesomeIcon("red");

  return (
    <MapContainer
      center={sourceCoords}
      zoom={8}
      style={{ height: "350px", width: "100%" }}
    >
      {/* Map Tiles */}
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />

      {/* Source Marker */}
      <Marker position={sourceCoords} icon={sourceIcon}>
        <Popup>{`مبدا: ${source}`}</Popup>
      </Marker>

      {/* Destination Marker */}
      <Marker position={destinationCoords} icon={destinationIcon}>
        <Popup>{`مقصد: ${destination}`}</Popup>
      </Marker>

      {/* Path between Source and Destination */}
      <Polyline
        positions={polyline}
        color="blue"
        weight={3}
        opacity={0.7}
        dashArray="5, 10"
      />
    </MapContainer>
  );
};

export default TourMap;
